import { LOCALE_ID, ViewChild, ElementRef, OnDestroy, Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef } from '@angular/core';

import { ApiRequestsService } from 'src/services/api-requests.service';

@Component({
  selector: 'app-point-of-interest-list',
  templateUrl: './point-of-interest-list.component.html',
  styleUrls: ['./point-of-interest-list.component.scss']
})
export class PointOfInterestListComponent implements OnInit, AfterViewInit, OnDestroy {
  navigatorRef: any;
  hasSearch = false;
  windowRef: any = window;

  LOCATION_ACCESS = 'Please enable access for SightGuide to your device Location';

  @ViewChild('languageRef') languageRef: ElementRef;
  @ViewChild('voiceRef') voiceRef: SpeechSynthesisVoice;
  // @ViewChild('geoBtn', { static: false }) geoBtn: ElementRef;
  // @ViewChild('input', { static: false }) searchfield: ElementRef;

  filtercategorie = null;
  isInit = false;
  useFilter = false;
  isInfo = false;
  isConfig = false;
  loading = false;
  isDriveByMode = false;
  wikiLanguage: string = 'en';
  keyword: string = 'test';
  POIResults: any = [{}];
  textIntro: string = '';
  location: any = [37.788844, -122.403042];
  voices: SpeechSynthesisVoice[];
  TTS: SpeechSynthesisUtterance;
  init: boolean;
  voice: SpeechSynthesisVoice;
  geodisplay: string;
  vip: boolean = false;
  headline: string;
  infoText: string;
  wikiImage: string;
  showDetailedView: boolean = false;
  speechSynthesis: SpeechSynthesis;
  icon: any = [];
  icons: any = [];
  iconTypeString: any = [];
  displayedIcon: any = [];
  artikleCoordinates: any = [];
  hasFilter: boolean;
  filterList: any = [];
  isChrome: boolean = false;
  filterAll: boolean = false;
  isMobileApp: boolean = false;
  // adsbygoogle: any;
  timeout: any;
  isAndroid: boolean = false;
  isApple: boolean = false;
  mapsWasOpened: boolean;
  getResultSubscribtion: any = null;
  coordinates: any;
  isManualClicked: boolean = false;
  coordinatesSubscription: any = null;
  resultListSubscription: any = null;
  spoken: any = [];
  isSearch: boolean = false;
  NO_RESULT = "No Result yet <br> Maybe you can write your artikle in Wikipedia about it. <br> <b>Hint:</b> Please use right syntax e.G.: <br> <b><i>St. Peter's Basilica.</i></b>";
  pageImage: any;
  author: any;
  extmetadata: any;
  heading: number;
  speed: number = 1;
  rad: any;
  poi: any;
  lastLocation: any = [];
  distance: any;
  rotateDegrees: number = 0;
  debugMode: boolean = false;
  userAgent: string;
  interval: any = null;
  isFriendly: any;
  isFeatured = false;
  searchRunning: boolean = false;
checked: any;
  loggerInfo(...log) {
    if (this.debugMode) {
      console.log('logging: ', ...log);
    }
  }

  // private loadScript(url: string) {
  //   this.loggerInfo('preparing to load...');
  //   const node = document.createElement('script');
  //   node.src = url;
  //   node.type = 'text/javascript';
  //   node.async = true;
  //   // tslint:disable-next-line: deprecation
  //   node.charset = 'utf-8';
  //   node.setAttribute('data-ad-client', 'ca-pub-5796806471143246');
  //   document.getElementsByTagName('head')[0].appendChild(node);
  //   this.timeout = setTimeout(() => { this.setAdd(); }, 2000);
  // }

  // setAdd() {
  //   if (this.windowRef.adsbygoogle) {
  //     this.adsbygoogle = this.windowRef.adsbygoogle || [];
  //     this.adsbygoogle.push({});
  //     this.loggerInfo('adsbygoogle', this.adsbygoogle);
  //     this.timeout = null;
  //   }
  // }
  // isScreenLockSupported() {
  //   return ('wakeLock' in navigator);
  // }

  constructor(private service: ApiRequestsService,
    // tslint:disable-next-line:align
    @Inject(LOCALE_ID) public locale: string, public cdk: ChangeDetectorRef) {
    this.loggerInfo('hostname', window.top.location.href);
    locale = navigator.language.toString().split('-')[0];
    this.locale = locale;
    const navigatorRef: any = navigator;
    // this.reloader();
    // let screenLock = navigatorRef.locks;
    // document.addEventListener('visibilitychange', async () => {
    //   if (screenLock !== null && document.visibilityState === 'visible') {
    //     screenLock = await navigatorRef.locks.request('screen', () => {
    //       screenLock.request('release', () => {
    //         this.loggerInfo('screenLock.release()');
    //       });
    //     });
    //   };
    // });
    if (window.top.location.href.includes('debugMode=true')) {
      this.debugMode = true;
    }
    if (window.top.location.href.includes('i-phone-payed-app-webview-version=yes')
      || window.top.location.pathname.includes('i-phone-payed-app-webview-version=yes')) {
      this.isMobileApp = true;
      this.loggerInfo('isMobileApp', this.isMobileApp);
      if (window.top.location.href.includes('&lang=') || window.top.location.pathname.includes('&lang=')) {
        locale = window.top.location.href.split('&lang=')[1];
        if (locale.includes('-')) {
          locale = locale.split('-')[0];
        }
      }
    } else {
      this.isMobileApp = false;
      // this.loadScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js');

    }

    if (speechSynthesis) {
      this.TTS = new SpeechSynthesisUtterance();
      this.TTS.text = '';
      this.loading = false;
      // alert('locale:' + this.locale);
      this.navigatorRef = navigator;
      this.wikiLanguage = locale;
      // (window.document.getElementsByTagName('body')[0] as unknown as HTMLButtonElement).onclick = (() => {
      //   this.loggerInfo('speak init'); this.speak('');
      // });
      (window.document.getElementsByTagName('body')[0] as unknown as HTMLButtonElement).blur();
      this.getVoices();
      this.speechSynthesis = speechSynthesis;
    }
    this.userAgent = this.getUserAgent();
    this.loggerInfo('userAgent', this.userAgent);
  }

  setFilter(icon: string) {
    // this.loggerInfo('setFilter| ' + icon);
    this.loggerInfo('setFilter|list: ' + icon, this.filterList);
    const index = this.filterList.indexOf(icon.toString());
    if (index > -1) {
      this.filterList.splice(index, 1);
      this.loggerInfo('setFilter|removed: ' + icon, this.filterList);
    } else {
      this.filterList.push(icon.toString());
      this.loggerInfo('setFilter|added: ' + icon);
    }
    // this.loggerInfo('setFilter|filters: ' + this.filterList);
    if (this.filterList.length > 0) {
      this.loggerInfo('setFilter|has-filters: ' + this.filterList);
      this.hasFilter = true;
    } else {
      this.loggerInfo('setFilter|no filters: ' + this.filterList);
      this.hasFilter = false;
    }
    this.loading = true;
    this.POIResults = [];
    if (!this.filterAll) {
      this.getListResult(this.location[0], this.location[1]);
    }
  }

  setFilterAll() {
    this.loggerInfo('filterList|filterAll: ' + this.filterList, this.filterAll, this.hasFilter);
    this.filterAll = !this.filterAll;
    this.filterList = [];
    this.hasFilter = false;
    if (this.filterAll) {
      this.icons.forEach((icon) => {
        this.filterList.push(icon.toString());
        this.hasFilter = true;
      });
      this.loggerInfo('filterList|filterAll,hasFilter: ' + this.filterList, this.filterAll, this.hasFilter);
    }
    this.getListResult(this.location[0], this.location[1]);
    // window.document.getElementsByTagName('button')[0].blur();
  }

  // setFocusOnSearch() {
  //   setInterval(() => {
  //     window.document.body.getElementsByTagName('input')[0].focus();
  //   }, 1000);
  // }

  // click() {
  //   this.getDeviceOrientation();
  // }
  checkGeo() {
    this.geodisplay = 'red';
    this.getDeviceOrientation();
    this.getCurrentLocation();
    if ('geolocation' in navigator && navigator.permissions !== undefined) {
      navigator.permissions.query({
        name: 'geolocation'
      }).then((result) => {
        if (result.state === 'granted') {
          this.loggerInfo(result.state);
          this.geodisplay = 'green';
        } else if (result.state === 'prompt') {
          this.loggerInfo(result.state);
          this.geodisplay = 'violett';
          this.getCurrentLocation();
        } else if (result.state === 'denied') {
          alert('Please enable device LOCATION');
          this.loggerInfo(result.state);
          this.geodisplay = 'red';
        }
        result.onchange = () => {
          // this.loggerInfo(result.state);
          this.geodisplay = 'orange';
        };

      });
    } else if (!('geolocation' in navigator)) {
      alert('Please enable device LOCATION');
    }
  }
  setIconFilterNames() {
    if (this.wikiLanguage.includes('de')) {
      this.iconTypeString[0] = 'Weltkulturerbe ';
      this.iconTypeString[1] = 'Wallfahrtsort ';
      this.iconTypeString[2] = ' kirche';
      this.iconTypeString[3] = 'Kirche ';
      this.iconTypeString[4] = ' kloster';
      this.iconTypeString[5] = 'Kloster ';
      this.iconTypeString[6] = ' antiker';
      this.iconTypeString[7] = 'Antiker ';
      this.iconTypeString[9] = 'Tempel ';
      this.iconTypeString[8] = ' tempel';
      this.iconTypeString[11] = 'Museum ';
      this.iconTypeString[10] = ' museum';
      this.iconTypeString[12] = 'Kunst ';
      this.iconTypeString[13] = ' see';
      this.iconTypeString[14] = 'See ';
      this.iconTypeString[15] = 'denkmal ';
      this.iconTypeString[16] = 'Denkmal ';
      this.iconTypeString[17] = ' Burg';
      this.iconTypeString[18] = 'Burg';
      this.iconTypeString[19] = 'Schloss';
      this.iconTypeString[20] = ' Schloss';
      this.iconTypeString[22] = 'Theater ';
      this.iconTypeString[21] = ' theater';
      // deleted dupplicate this.iconTypeString[24] = 'Antiker ';
      // deleted dupplicate this.iconTypeString[23] = ' antiker';
      this.iconTypeString[25] = 'Restaurant';
      this.iconTypeString[26] = ' statue';
      this.iconTypeString[27] = 'Statue ';
      this.iconTypeString[28] = ' zoo';
      this.iconTypeString[29] = 'Zoo ';
      this.iconTypeString[30] = ' wildpark';
      this.iconTypeString[31] = 'Wildpark ';
      this.iconTypeString[32] = ' tourismus';
      this.iconTypeString[33] = 'Tourismus ';
      this.iconTypeString[34] = ' film';
      this.iconTypeString[35] = 'Film ';
      this.iconTypeString[36] = ' kino';
      this.iconTypeString[37] = 'Kino ';
      this.iconTypeString[38] = ' veranstaltungen';
      this.iconTypeString[39] = 'Veranstaltungen ';
      this.iconTypeString[40] = ' disco';
      this.iconTypeString[41] = 'Disco ';
      this.iconTypeString[42] = ' parkanlage';
      this.iconTypeString[43] = 'Parkanlage ';
      this.iconTypeString[44] = ' garten';
      this.iconTypeString[45] = 'Garten ';
      this.iconTypeString[46] = ' pferd';
      this.iconTypeString[47] = 'Pferd ';
      this.iconTypeString[23] = 'Gebirge';
      this.iconTypeString[24] = 'Berglandschaft';
      this.iconTypeString[48] = ' strand';
      this.iconTypeString[49] = 'Strand ';
      this.iconTypeString[50] = ' badeanlage';
      this.iconTypeString[51] = 'Badeanlage ';
      this.iconTypeString[52] = ' synagoge';
      this.iconTypeString[53] = 'Synagoge ';
    } else if (this.wikiLanguage.includes('zh')) {
      this.iconTypeString[0] = '世界遗产';
      this.iconTypeString[1] = '朝圣';
      this.iconTypeString[2] = '教会';
      this.iconTypeString[3] = '教会';
      this.iconTypeString[4] = '修道院';
      this.iconTypeString[5] = '修道院';
      this.iconTypeString[6] = '古董';
      this.iconTypeString[7] = '古董';
      this.iconTypeString[8] = '寺庙';
      this.iconTypeString[9] = '寺庙';
      this.iconTypeString[10] = '博物馆';
      this.iconTypeString[11] = '博物馆';
      this.iconTypeString[12] = '艺术';
      this.iconTypeString[13] = '湖';
      this.iconTypeString[14] = '湖';
      this.iconTypeString[15] = '纪念碑';
      this.iconTypeString[16] = '纪念碑';
      this.iconTypeString[17] = '城堡';
      this.iconTypeString[18] = '城堡';
      this.iconTypeString[19] = '城堡';
      this.iconTypeString[20] = '城堡';
      this.iconTypeString[21] = '剧院';
      this.iconTypeString[22] = '剧院';
      // deleted dupplicate this.iconTypeString[23] = '古';
      // deleted dupplicate this.iconTypeString[24] = '古';
      this.iconTypeString[25] = '餐厅';


      this.iconTypeString[26] = '雕像';
      this.iconTypeString[27] = '雕像';
      this.iconTypeString[28] = '动物园';
      this.iconTypeString[29] = '动物园';
      this.iconTypeString[30] = '公园';
      this.iconTypeString[31] = '公园';
      this.iconTypeString[32] = '游客';
      this.iconTypeString[33] = '游客';
      this.iconTypeString[34] = '电影';
      this.iconTypeString[35] = '电影';
      this.iconTypeString[36] = '电影院';
      this.iconTypeString[37] = '电影院';
      this.iconTypeString[38] = '事件';
      this.iconTypeString[39] = '事件';
      this.iconTypeString[40] = '迪斯科';
      this.iconTypeString[41] = '迪斯科';
      this.iconTypeString[42] = '花园';
      this.iconTypeString[43] = '花园';
      this.iconTypeString[44] = '花园';
      this.iconTypeString[45] = '花园';
      this.iconTypeString[46] = '马';
      this.iconTypeString[47] = '马';
      this.iconTypeString[23] = '山';
      this.iconTypeString[24] = '山';
      this.iconTypeString[48] = '海滩';
      this.iconTypeString[49] = '海滩';
      this.iconTypeString[50] = '游泳的';
      this.iconTypeString[51] = '游泳的';
      this.iconTypeString[52] = '会堂';
      this.iconTypeString[53] = '会堂';

    } else if (this.wikiLanguage.includes('fr')) {
      this.iconTypeString[0] = 'Héritage du monde';
      this.iconTypeString[1] = 'Pèlerinage';
      this.iconTypeString[2] = 'église';
      this.iconTypeString[3] = 'Église';
      this.iconTypeString[4] = 'Monastère';
      this.iconTypeString[5] = 'monastère';
      this.iconTypeString[6] = 'Antique';
      this.iconTypeString[7] = 'antique';
      this.iconTypeString[8] = 'Temple';
      this.iconTypeString[9] = 'temple';
      this.iconTypeString[10] = 'Musée';
      this.iconTypeString[11] = 'musée';
      this.iconTypeString[12] = 'art';
      this.iconTypeString[13] = 'Lac';
      this.iconTypeString[14] = 'lac';
      this.iconTypeString[15] = 'Monument';
      this.iconTypeString[16] = 'monument';
      this.iconTypeString[17] = 'Châteaus';
      this.iconTypeString[18] = 'châteaus';
      this.iconTypeString[19] = 'Château';
      this.iconTypeString[20] = 'château';
      this.iconTypeString[21] = 'Théâtre';
      this.iconTypeString[22] = 'théâtre';
      this.iconTypeString[23] = 'Montagnes';
      this.iconTypeString[24] = 'montagnes';
      this.iconTypeString[25] = 'Montagne';

      this.iconTypeString[26] = 'Statue';
      this.iconTypeString[27] = 'Statue';
      this.iconTypeString[28] = 'Zoo';
      this.iconTypeString[29] = 'zoo';
      this.iconTypeString[30] = 'Réserve naturelle';
      this.iconTypeString[31] = 'réserve naturelle';
      this.iconTypeString[32] = 'Touristique';
      this.iconTypeString[33] = 'touristique';
      this.iconTypeString[34] = 'Film';
      this.iconTypeString[35] = 'film';
      this.iconTypeString[36] = 'Cinéma';
      this.iconTypeString[37] = 'cinéma';
      this.iconTypeString[38] = 'Evénement';
      this.iconTypeString[39] = 'evénement';
      this.iconTypeString[40] = 'Les boites de nuit';
      this.iconTypeString[41] = 'Les boites de nuit';
      this.iconTypeString[42] = 'Jardin';
      this.iconTypeString[43] = 'jardin';
      this.iconTypeString[44] = 'Espace vert';
      this.iconTypeString[45] = 'espace vert';
      this.iconTypeString[46] = 'Hippique';
      this.iconTypeString[47] = 'Cheval';
      //    this.iconTypeString[48] = 'mountain';
      //   this.iconTypeString[49] = 'Mountain';
      this.iconTypeString[48] = 'Plage';
      this.iconTypeString[49] = 'plage';
      this.iconTypeString[50] = 'Piscine';
      this.iconTypeString[51] = 'piscine';
      this.iconTypeString[52] = 'Synagogue';
      this.iconTypeString[53] = 'synagogue';

    } else if (this.wikiLanguage.includes('it')) {
      this.iconTypeString[0] = "Patrimonio dell'umanità";
      this.iconTypeString[1] = 'pellegrinaggio';
      this.iconTypeString[2] = 'Chiesa';
      this.iconTypeString[3] = 'chiesa';
      this.iconTypeString[4] = 'Monastero';
      this.iconTypeString[5] = 'monastero';
      this.iconTypeString[6] = 'Archeologici';
      this.iconTypeString[7] = 'archeologici';
      this.iconTypeString[8] = 'Templi';
      this.iconTypeString[9] = 'templi';
      this.iconTypeString[10] = 'Museo';
      this.iconTypeString[11] = 'Musei';
      this.iconTypeString[12] = 'Arte';
      this.iconTypeString[13] = 'Lago';
      this.iconTypeString[14] = 'laghi';
      this.iconTypeString[15] = 'Monumenti';
      this.iconTypeString[16] = 'monumenti';
      this.iconTypeString[17] = 'Castelli';
      this.iconTypeString[18] = 'castelli';
      this.iconTypeString[19] = 'Castello';
      this.iconTypeString[20] = 'castello';
      this.iconTypeString[21] = 'Teatri';
      this.iconTypeString[22] = 'teatri';
      this.iconTypeString[23] = 'Montagna';
      this.iconTypeString[24] = 'montagne';
      this.iconTypeString[25] = 'Ristorante';

      this.iconTypeString[26] = 'Sculture';
      this.iconTypeString[27] = 'Sculture';
      this.iconTypeString[28] = 'Zoo';
      this.iconTypeString[29] = 'zoo';
      this.iconTypeString[30] = 'Giardini zoologici';
      this.iconTypeString[31] = 'giardini zoologici';
      this.iconTypeString[32] = 'Turista';
      this.iconTypeString[33] = 'turista';
      this.iconTypeString[34] = 'Film';
      this.iconTypeString[35] = 'film';
      this.iconTypeString[36] = 'Cinema';
      this.iconTypeString[37] = 'cinema';
      this.iconTypeString[38] = 'Eventi';
      this.iconTypeString[39] = 'eventi';
      this.iconTypeString[40] = 'Club';
      this.iconTypeString[41] = 'Nightclubs';
      this.iconTypeString[42] = 'Aree naturali';
      this.iconTypeString[43] = 'aree naturali';
      this.iconTypeString[44] = 'Giardini';
      this.iconTypeString[45] = 'giardini';
      this.iconTypeString[46] = 'Cavalli';
      this.iconTypeString[47] = 'Cavalli';
      //    this.iconTypeString[48] = 'mountain';
      //   this.iconTypeString[49] = 'Mountain';
      this.iconTypeString[48] = 'Spiagge';
      this.iconTypeString[49] = 'Spiagge';
      this.iconTypeString[50] = 'Nuoto';
      this.iconTypeString[51] = 'nuoto';
      this.iconTypeString[52] = 'Sinagoga';
      this.iconTypeString[53] = 'sinagoga';

    } else if (this.wikiLanguage.includes('es')) {
      this.iconTypeString[0] = 'Herencia mundial';
      this.iconTypeString[1] = 'Peregrinaje';
      this.iconTypeString[2] = 'Iglesia';
      this.iconTypeString[3] = 'iglesia';
      this.iconTypeString[4] = 'Monasterio';
      this.iconTypeString[5] = 'monasterio';
      this.iconTypeString[6] = 'Antiguo';
      this.iconTypeString[7] = 'antiguo';
      this.iconTypeString[8] = 'Templo';
      this.iconTypeString[9] = 'templo';
      this.iconTypeString[10] = 'Museos';
      this.iconTypeString[11] = 'museos';
      this.iconTypeString[12] = 'Artes';
      this.iconTypeString[13] = 'Lagos';
      this.iconTypeString[14] = 'lagos';
      this.iconTypeString[15] = 'Monumentos';
      this.iconTypeString[16] = 'monumentos';
      this.iconTypeString[17] = 'Catedral';
      this.iconTypeString[18] = 'catedral';
      this.iconTypeString[19] = 'Castillos';
      this.iconTypeString[20] = 'castillos';
      this.iconTypeString[21] = 'Teatros';
      this.iconTypeString[22] = 'teatros';
      this.iconTypeString[23] = 'Montaña';
      this.iconTypeString[24] = 'Montañas';
      this.iconTypeString[25] = 'Restaurante';

      this.iconTypeString[26] = 'Esculturas';
      this.iconTypeString[27] = 'esculturas';
      this.iconTypeString[28] = 'Zoo';
      this.iconTypeString[29] = 'zoo';
      this.iconTypeString[30] = 'oológicos';
      this.iconTypeString[31] = 'Zoológicos';
      this.iconTypeString[32] = 'Turista';
      this.iconTypeString[33] = 'turista';
      this.iconTypeString[34] = 'Película';
      this.iconTypeString[35] = 'película';
      this.iconTypeString[36] = 'Cine';
      this.iconTypeString[37] = 'cine';
      this.iconTypeString[38] = 'Event';
      this.iconTypeString[39] = 'event';
      this.iconTypeString[40] = 'Club';
      this.iconTypeString[41] = 'Discotecas';
      this.iconTypeString[42] = 'Parques';
      this.iconTypeString[43] = 'Parques';
      this.iconTypeString[44] = 'Jardines';
      this.iconTypeString[45] = 'jardines';
      this.iconTypeString[46] = 'caballo';
      this.iconTypeString[47] = 'caballo';
      //    this.iconTypeString[48] = 'mountain';
      //   this.iconTypeString[49] = 'Mountain';
      this.iconTypeString[48] = 'Playa';
      this.iconTypeString[49] = 'playa';
      this.iconTypeString[50] = 'Nadando';
      this.iconTypeString[51] = 'nadando';
      this.iconTypeString[52] = 'Sinagoga';
      this.iconTypeString[53] = 'sinagoga';

    } else {
      this.iconTypeString[0] = 'World Heritage';
      this.iconTypeString[1] = 'Pilgrimage';
      this.iconTypeString[2] = 'Church';
      this.iconTypeString[3] = 'church';
      this.iconTypeString[4] = 'Monasteries';
      this.iconTypeString[5] = 'monastery';
      this.iconTypeString[6] = 'Antique';
      this.iconTypeString[7] = 'antique';
      this.iconTypeString[8] = 'Temples';
      this.iconTypeString[9] = 'temple';
      this.iconTypeString[10] = 'Museums';
      this.iconTypeString[11] = 'museums';
      this.iconTypeString[12] = 'Arts';
      this.iconTypeString[13] = 'Lakes';
      this.iconTypeString[14] = 'lake';
      this.iconTypeString[15] = 'Monuments';
      this.iconTypeString[16] = 'monuments';
      this.iconTypeString[17] = 'Castles';
      this.iconTypeString[18] = 'castles';
      this.iconTypeString[19] = 'Castle';
      this.iconTypeString[20] = 'castle';
      this.iconTypeString[21] = 'Theatres';
      this.iconTypeString[22] = 'theatre';
      this.iconTypeString[23] = 'Mountains';
      this.iconTypeString[24] = 'mountain';
      this.iconTypeString[25] = 'Restaurant';

      this.iconTypeString[26] = 'Statues';
      this.iconTypeString[27] = 'statues';
      this.iconTypeString[28] = 'Zoos';
      this.iconTypeString[29] = 'Zoos';
      this.iconTypeString[30] = 'Wildlife';
      this.iconTypeString[31] = 'wildlife';
      this.iconTypeString[32] = 'Tourist';
      this.iconTypeString[33] = 'tourist';
      this.iconTypeString[34] = 'Movie';
      this.iconTypeString[35] = 'movie';
      this.iconTypeString[36] = 'Cinema';
      this.iconTypeString[37] = 'cinemas';
      this.iconTypeString[38] = 'Events';
      this.iconTypeString[39] = 'events';
      this.iconTypeString[40] = 'Club';
      this.iconTypeString[41] = 'Nightclubs';
      this.iconTypeString[42] = 'Garden';
      this.iconTypeString[43] = 'garden';
      this.iconTypeString[44] = 'Gardens';
      this.iconTypeString[45] = 'gardens';
      this.iconTypeString[46] = 'Horse';
      this.iconTypeString[47] = 'horse';
      //    this.iconTypeString[48] = 'mountain';
      //   this.iconTypeString[49] = 'Mountain';
      this.iconTypeString[48] = 'Beach';
      this.iconTypeString[49] = 'beach';
      this.iconTypeString[50] = 'Swimming';
      this.iconTypeString[51] = 'swimming';
      this.iconTypeString[52] = 'Synagogue';
      this.iconTypeString[53] = 'synagogue';
    }
  }
  ngOnInit() {
    this.loading = false;
    this.isChrome = this.getBrowser() === 'Netscape';
    this.isAndroid = this.isChrome && this.service.getOS() === 'Linux' ? true : false;
    this.loggerInfo(this.service.getOS());
    this.isApple = this.service.getOS() === 'MacOS' || this.service.getOS() === 'OS' ? true : false;
    this.loggerInfo('is isApple:', this.isApple);
    this.loggerInfo('is isAndroid:', this.isAndroid);
    // this.vip = this.getOS() === 'Linux';
    // this.isChrome = this.getOS() === 'Linux' && (this.getBrowser() === 'Chrome' || this.getBrowser() === 'Netscape');
    this.loggerInfo('is VIP:', this.vip);
    this.loggerInfo('locale', this.locale);
    this.setLocale(this.locale);
    this.getVoices();
    // Icon defintion for piktograms
    this.icons[0] = 'place-of-worship';
    this.icons[1] = 'place-of-worship';
    this.icons[2] = 'church';
    this.icons[3] = 'church';
    this.icons[4] = 'pray';
    this.icons[5] = 'pray';
    this.icons[6] = 'scroll';
    this.icons[7] = 'scroll';
    this.icons[8] = 'landmark';
    this.icons[9] = 'landmark';
    this.icons[10] = 'eye';
    this.icons[11] = 'eye';
    this.icons[12] = 'palette';
    this.icons[13] = 'water';
    this.icons[14] = 'water';
    this.icons[15] = 'archway';
    this.icons[16] = 'archway';
    this.icons[17] = 'chess-rook';
    this.icons[18] = 'chess-rook';
    this.icons[19] = 'crown';
    this.icons[20] = 'crown';
    this.icons[21] = 'theater-masks';
    this.icons[22] = 'theater-masks';
    this.icons[23] = 'mountain';
    this.icons[24] = 'mountain';
    // this.icons[23] = 'scroll';
    // this.icons[24] = 'scroll';
    this.icons[25] = 'utensils';
    this.icons[26] = 'monument';
    this.icons[27] = 'monument';
    this.icons[28] = 'hippo';
    this.icons[29] = 'hippo';
    this.icons[30] = 'paw';
    this.icons[31] = 'paw';
    this.icons[32] = 'camera';
    this.icons[33] = 'camera';
    this.icons[34] = 'file-video';
    this.icons[35] = 'file-video';
    this.icons[36] = 'film';
    this.icons[37] = 'film';
    this.icons[38] = 'calendar-check';
    this.icons[39] = 'calendar-check';
    this.icons[40] = 'compact-disc';
    this.icons[41] = 'compact-disc';
    this.icons[42] = 'tree';
    this.icons[43] = 'tree';
    this.icons[44] = 'leaf';
    this.icons[45] = 'leaf';
    this.icons[46] = 'horse-head';
    this.icons[47] = 'horse-head';

    this.icons[48] = 'umbrella-beach';
    this.icons[49] = 'umbrella-beach';
    this.icons[50] = 'swimmer';
    this.icons[51] = 'swimmer';
    this.icons[52] = 'synagogue';
    this.icons[53] = 'synagogue';

    this.getCurrentLocation();
    // this.setFilterAll();
    if (this.isChrome) {
      setTimeout(() => { this.watchLocation(); }, 2000);
    }
    // window.document.body.querySelector('iframe').querySelector('video').addEventListener('click', this.waitForVideo);
  }

  getBrowser() {
    this.loggerInfo(navigator.appName, 'Browser');
    return navigator.appName;
  }

  search(value) {

    if (this.searchRunning) {
      console.log('searchRunning');
      return;
    }

    if (value.toString().toLowerCase().includes('+')) {
      this.speed = 1;
    }

    this.loggerInfo(value);
    if (value.length > 3) {
      this.getCoordinates(value);
    } else {
      this.stopTalk();
    }
  }

  ngAfterViewInit() {
    this.getVoices();
    // if (this.isMobileApp) {
    this.checkGeo();
    // }
    this.isDriveByMode = document.location.toString().includes('?auto=true') ? true : false;
    this.isManualClicked = false;
    this.cdk.detectChanges();
    this.reloadContent();
  }

  reloadContent() {
    const parameters: string = document.location.pathname;
    const poi = {
      title: '',
      thumbnail: { source: '' },
      source: '',
      pageimage: '',
      rad: '',
      distance: '',
    };

    poi.title = parameters.split('?title=')[0].split('&source=')[0];
    poi.thumbnail.source = parameters.split('&source=')[0].split('&pageimage=')[0];
    poi.pageimage = parameters.split('&pageimage=')[0].split('&rad=')[0];
    poi.rad = parameters.split('&rad=')[0].split('&distance=')[0];
    poi.distance = parameters.split('&distance=')[0];

    if (poi.title !== '') {
      this.getDetails(
        poi.title,
        poi.thumbnail && poi.source
          ? poi.source
          : null,
        poi.pageimage,
        poi.rad,
        poi.distance,
        poi
      );
    }
  }
  getDetails(keyword, itemImage?, pageimage?, rad?, distance?, poi?) {

    this.loggerInfo('getDetails rad', rad);
    this.rad = (rad);
    this.poi = poi;
    this.distance = distance;

    this.loggerInfo('getDetails distance', distance);
    if (!this.isManualClicked && this.isDriveByMode && speechSynthesis.speaking) {
      return;
    }
    if (!this.isManualClicked && this.isDriveByMode && this.spoken.includes(keyword)) {
      this.loggerInfo(keyword, this.spoken, 'denied to speak');
      return;
    }
    this.spoken.push(keyword);
    this.loading = true;
    this.loggerInfo('getTextIntroResult|authorObj = ', pageimage);
    this.getAuthorForTemplates(pageimage);
    setTimeout(() => {
      this.getTextIntroResult(keyword, itemImage, rad, distance);
    }, 1000);
  }

  // reloader() {
  //   setTimeout(() => {
  //     if (!this.speechSynthesis.speaking) {
  //       document.location.href = document.location + '?auto=' + this.isDriveByMode;
  //     //     + '?title=' + this.poi.title
  //     //     + '&source=' + this.poi.source
  //     //     + '&pageimage=' + this.poi.pageimage
  //     //     + '&source=' + this.poi.thumbnail.source
  //     //     + '&rad=' + this.poi.rad
  //     //     + '&distance=' + this.poi.distance
  //     }
  //   }, 20000);
  // }
  getCoordinates(keyword, itemImage?) {
    this.loading = true;
    //    setTimeout(() => {
    this.getTextCoordinatesResult(keyword, itemImage);
    //  }, 1000);
  }
  getVoices() {
    if (speechSynthesis) {
      this.voices = speechSynthesis.getVoices();
      this.loggerInfo('voices', speechSynthesis.getVoices());
      this.voice = this.TTS.voice;
      this.voices.forEach((value, index) => {
        this.loggerInfo(value.lang, ' to find ', this.locale);
        if (value.lang.includes(this.locale)) {
          this.voice = this.voices[index];
          this.loggerInfo(this.voice, ' is used');
        }
      });
      this.loggerInfo('voice', this.voice, ' is used');
      /*
      if (this.voice === null) {
        this.voice = this.voices.filter((voice) => voice.name === 'Siri');
      }
      */
    }
  }

  getAuthorForTemplates(pageimage) {
    this.loggerInfo('getTextIntroResult|authorObj = ', pageimage);
    this.extmetadata = this.service.getMetadataByImageNameUrl(pageimage, this.wikiLanguage, this.isFeatured).subscribe((response: any) => {
      this.loggerInfo('getTextIntroResult|authorObj = ', response);
      const data: any = response;
      if (data && data.query && data.query.pages && Object.keys(data.query.pages)) {
        const pages = data.query.pages;

        const pagesList = Object.keys(pages);
        // this.loggerInfo('pages[pagesList[0]].imageinfo[0]', pages[pagesList[0]].imageinfo[0].extmetadata);
        // this.loggerInfo('LicenseShortName', pages[pagesList[0]].imageinfo[0].extmetadata.LicenseShortName.value);
        // this.loggerInfo('Artist', pages[pagesList[0]].imageinfo[0].extmetadata.Artist.value);
        this.extmetadata = pages[pagesList[0]].imageinfo && pages[pagesList[0]].imageinfo[0]
          && pages[pagesList[0]].imageinfo[0].extmetadata ? pages[pagesList[0]].imageinfo[0].extmetadata : null;
      }
    });
  }

  getTextCoordinatesResult(keyword, itemImage = null) {
    if (this.coordinatesSubscription) {
      this.service.getCoordinatesResult(keyword, this.wikiLanguage, this.isFeatured);
    }
    this.coordinatesSubscription = this.service.getCoordinatesResult(keyword, this.wikiLanguage, this.isFeatured)
      .subscribe((response: any) => {
        this.loggerInfo('getCoordinatesResult|response = ', response);
        this.loading = false;
        const data = response;
        this.searchRunning = false;
        if (data.query && data.query.pages) {
          const pages = data.query.pages;
          if (pages[Object.keys(pages)[0]] && pages[Object.keys(pages)[0]].coordinates
            && pages[Object.keys(pages)[0]].coordinates[0]
            && pages[Object.keys(pages)[0]].coordinates[0].lat) {
            this.loggerInfo('getCoordinatesResult|pages = ', pages[Object.keys(pages)[0]].coordinates[0]);
            this.location[0] = pages[Object.keys(pages)[0]].coordinates[0].lat;
            this.location[1] = pages[Object.keys(pages)[0]].coordinates[0].lon;
            this.getListResult(this.location[0], this.location[1]);
          }

          if (pages && Object.keys(pages) && Object.keys(pages)[0] &&
            pages[Object.keys(pages)[0]] && pages[Object.keys(pages)[0]].thumbnail && pages[Object.keys(pages)[0]].thumbnail.source) {
            this.getDetails(keyword, pages[Object.keys(pages)[0]].thumbnail.source, pages[Object.keys(pages)[0]].pageimage);
          } else {
            this.getDetails(keyword, null, pages[Object.keys(pages)[0]].pageimage);
          }

        }
      });
  }

  getOpenMapsLink(poi) {
    if (!poi || !poi.coordinates || !poi.coordinates[0]) {
      return;
    }
    // this.loggerInfo('coordinates', this.coordinates);
    if (this.isApple) {
      return 'http://maps.apple.com/?q=' + poi.coordinates[0].lat + ',' + poi.coordinates[0].lon;
    } else {
      return 'https://maps.google.com/maps?daddr=' + poi.coordinates[0].lat + ',' + poi.coordinates[0].lon + '&amp;ll=';
    }
  }

  getTextIntroResult(keyword, itemImage, rad?, distance?) {
    this.loggerInfo('getTextIntroResult rad', rad, distance, keyword);
    if (this.getResultSubscribtion) {
      this.service.getTextIntroResult(keyword, this.wikiLanguage, this.isFeatured);
    }
    this.getResultSubscribtion = this.service.getTextIntroResult(keyword, this.wikiLanguage, this.isFeatured)
      .subscribe((response: any) => {
        if (this.mapsWasOpened) {
          this.mapsWasOpened = false;
          this.loading = false;
          return;
        }
        this.loggerInfo('getTextIntroResult|response = ', response);
        this.loading = false;
        const data = response;
        if (data.query) {
          const pages = data.query.pages;
          const wikiImage = itemImage;
          if (!(pages && Object.keys(pages)[0] && pages[Object.keys(pages)[0]] && pages[Object.keys(pages)[0]].extract)) {
            return;
          }
          const extract = pages[Object.keys(pages)[0]].extract.toString();

          this.loggerInfo(extract);
          let textIntro = extract;
          if (extract === undefined || extract === null) {
            textIntro = this.NO_RESULT;
          } else {
            this.POIResults.forEach((item, index) => {
              if (item.title === keyword) {
                this.artikleCoordinates = [];
                this.artikleCoordinates = [this.POIResults[index].coordinates[0].lat, this.POIResults[index].coordinates[0].lon];
                this.loggerInfo('articleCoordinates', this.artikleCoordinates);
                this.pageImage = item.pageimage;
              }
            });
          }
          this.textIntro = this.vip ? extract.substr(0, 200) : extract;
          this.loggerInfo('textIntro', textIntro, textIntro.length);
          if (textIntro.lastIndexOf('.') !== -1) {
            textIntro = textIntro.substr(0, textIntro.lastIndexOf('.'));
          }
          this.loggerInfo('textIntro', textIntro.length);
          this.textIntro = textIntro;

          this.setUpDetailedView(wikiImage, keyword, textIntro);
          this.loggerInfo(keyword, this.spoken, 'check to speak');
          if (this.isDriveByMode && !this.isManualClicked && !speechSynthesis.speaking) {
            this.speak(textIntro, keyword, rad, distance);
          } else if (this.isManualClicked) {
            this.isManualClicked = false;
            this.speak(textIntro, keyword, rad, distance);
          }
          // if linux system or PAID APP paramters -> requestMp3File(keyword.toString(), textIntro.toString());
        } else {
          this.loggerInfo('data.query was not found', data.query, keyword);
        }
      });
  }
  setUpDetailedView(wikiImage: string, keyword: string, textIntro: string) {
    this.showDetailedView = true;
    this.wikiImage = wikiImage;
    this.headline = keyword;
    this.infoText = textIntro;
  }
  // waitForVideo() {
  //   setTimeout(() => {
  //     this.isInit = true;
  //   }, 1111);
  // }

  speak(textIntro, keyword?, rad?, distance?) {
    this.loggerInfo('speak rad', rad, distance, textIntro);
    if (rad + Math.floor(this.rotateDegrees)) {
      rad = rad + Math.floor(this.rotateDegrees);
    }
    if (this.speed > 0) {
      this.loggerInfo('speak speed', this.speed);
      if (textIntro !== null) {
        this.loggerInfo('speak textIntro', textIntro);
        let compassDirection;
        if (this.wikiLanguage.includes('ru')) {
          if (rad > 0 % 360 && rad < 45 % 360) {
            compassDirection = 'по пра́вую' + ' перёд ';
          }

          if (rad > 45 % 360 && rad < 90 % 360) {
            compassDirection = 'по пра́вую';
          }

          if (rad > 125 % 360 && rad < 170 % 360) {
            compassDirection = 'по пра́вую' + ' позади́';
          }

          if (rad > 170 % 360 && rad < 215 % 360) {
            compassDirection = ' позади́';
          }

          if (rad > 215 % 360 && rad < 250 % 360) {
            compassDirection = 'по ле́вую' + ' позади́';
          }

          if (rad > 250 % 360 && rad < 305 % 360) {
            compassDirection = 'по ле́вую';
          }

          if (rad > 305 % 360 && rad < 345 % 360) {
            compassDirection = 'по ле́вую' + ' перёд ';
          }

          if (rad < 15 % 360 && rad > 345 % 360) {
            compassDirection = ' перёд ';
          }
        } else if (this.wikiLanguage.includes('es')) {

          if (rad > 0 % 360 && rad < 45 % 360) {
            compassDirection = 'a derecha' + ' delante de ti';
          }

          if (rad > 45 % 360 && rad < 90 % 360) {
            compassDirection = 'a derecha';
          }

          if (rad > 125 % 360 && rad < 170 % 360) {
            compassDirection = 'a derecha' + ' detrás de ti';
          }

          if (rad > 170 % 360 && rad < 215 % 360) {
            compassDirection = ' detrás de ti';
          }

          if (rad > 215 % 360 && rad < 250 % 360) {
            compassDirection = 'a izquierda' + ' detrás de ti';
          }

          if (rad > 250 % 360 && rad < 305 % 360) {
            compassDirection = 'a izquierda';
          }

          if (rad > 305 % 360 && rad < 345 % 360) {
            compassDirection = 'a izquierda' + ' delante de ti';
          }

          if (rad < 15 % 360 && rad > 345 % 360) {
            compassDirection = ' delante de ti';
          }
        } else if (this.wikiLanguage.includes('pt')) {

          if (rad > 0 % 360 && rad < 45 % 360) {
            compassDirection = 'à direito' + ' antes deles';
          }

          if (rad > 45 % 360 && rad < 90 % 360) {
            compassDirection = 'à direito';
          }

          if (rad > 125 % 360 && rad < 170 % 360) {
            compassDirection = 'à direito' + ' atràs deles';
          }

          if (rad > 170 % 360 && rad < 215 % 360) {
            compassDirection = ' atràs deles';
          }

          if (rad > 215 % 360 && rad < 250 % 360) {
            compassDirection = 'à esquerda' + ' atràs deles';
          }

          if (rad > 250 % 360 && rad < 305 % 360) {
            compassDirection = 'à esquerda';
          }

          if (rad > 305 % 360 && rad < 345 % 360) {
            compassDirection = 'à esquerda' + ' antes deles';
          }

          if (rad < 15 % 360 && rad > 345 % 360) {
            compassDirection = ' antes deles';
          }
        } else if (this.wikiLanguage.includes('it')) {

          if (rad > 0 % 360 && rad < 45 % 360) {
            compassDirection = 'a destra' + ' prima de voi';
          }

          if (rad > 45 % 360 && rad < 90 % 360) {
            compassDirection = 'a destra';
          }

          if (rad > 125 % 360 && rad < 170 % 360) {
            compassDirection = 'a destra' + ' dietro di voi';
          }

          if (rad > 170 % 360 && rad < 215 % 360) {
            compassDirection = ' dietro di voi';
          }

          if (rad > 215 % 360 && rad < 250 % 360) {
            compassDirection = 'a sinistra' + ' dietro di voi';
          }

          if (rad > 250 % 360 && rad < 305 % 360) {
            compassDirection = 'a sinistra';
          }

          if (rad > 305 % 360 && rad < 345 % 360) {
            compassDirection = 'a sinistra' + ' prima de voi';
          }

          if (rad < 15 % 360 && rad > 345 % 360) {
            compassDirection = ' prima de voi';
          }
        } else if (this.wikiLanguage.includes('fr')) {
          if (rad > 0 % 360 && rad < 45 % 360) {
            compassDirection = 'a droit' + ' devant eux';
          }

          if (rad > 45 % 360 && rad < 90 % 360) {
            compassDirection = 'a droit';
          }

          if (rad > 125 % 360 && rad < 170 % 360) {
            compassDirection = 'a droit' + ' derrière eux';
          }

          if (rad > 170 % 360 && rad < 215 % 360) {
            compassDirection = ' derrière eux';
          }

          if (rad > 215 % 360 && rad < 250 % 360) {
            compassDirection = 'a gauche' + ' derrière eux';
          }

          if (rad > 250 % 360 && rad < 305 % 360) {
            compassDirection = 'a gauche';
          }

          if (rad > 305 % 360 && rad < 345 % 360) {
            compassDirection = 'a gauche' + ' devant eux';
          }

          if (rad < 15 % 360 && rad > 345 % 360) {
            compassDirection = ' devant eux';
          }
        } else if (this.wikiLanguage.includes('de')) {
          if (rad > 0 % 360 && rad < 45 % 360) {
            compassDirection = 'Rechts' + ' Vor Ihnen';
          }
          if (rad > 45 % 360 && rad < 90 % 360) {
            compassDirection = 'Rechts';
          }
          if (rad > 125 % 360 && rad < 170 % 360) {
            compassDirection = 'Rechts' + ' Hinter Ihnen';
          }
          if (rad > 170 % 360 && rad < 215 % 360) {
            compassDirection = ' Hinter Ihnen';
          }
          if (rad > 215 % 360 && rad < 250 % 360) {
            compassDirection = 'Links' + ' Hinter Ihnen';
          }
          if (rad > 250 % 360 && rad < 305 % 360) {
            compassDirection = 'Links';
          }
          if (rad > 305 % 360 && rad < 345 % 360) {
            compassDirection = 'Links' + ' Vor Ihnen';
          }
          if (rad < 15 % 360 && rad > 345 % 360) {
            compassDirection = ' Vor Ihnen';
          }
        } else {
          if (rad > 0 % 360 && rad < 45 % 360) {
            compassDirection = 'Right' + ' in front of you';
          }
          if (rad > 45 % 360 && rad < 90 % 360) {
            compassDirection = 'Right';
          }
          if (rad > 125 % 360 && rad < 170 % 360) {
            compassDirection = 'Right' + ' behind you';
          }
          if (rad > 170 % 360 && rad < 215 % 360) {
            compassDirection = ' Behind you';
          }
          if (rad > 215 % 360 && rad < 250 % 360) {
            compassDirection = 'Left' + ' behind you';
          }
          if (rad > 250 % 360 && rad < 305 % 360) {
            compassDirection = 'Left';
          }
          if (rad > 305 % 360 && rad < 345 % 360) {
            compassDirection = 'Left' + ' in front of you';
          }
          if (rad < 15 % 360 && rad > 345 % 360) {
            compassDirection = ' in front of you';
          }
        }
        this.loggerInfo('speak compassDirection rad', rad);

        this.loggerInfo('speak compassDirection', compassDirection);

        if (compassDirection) {
          let preText = distance + ' Meter ' + compassDirection;
          this.loggerInfo('speak preText', preText);
          if (this.wikiLanguage.includes('de')) {
            preText = 'In ' + distance.toString() + ' Meter '
              + compassDirection + 'befindet sich ' + keyword;
          }
          textIntro = preText + ',,,' + textIntro;
        }
      }
    }
    if (speechSynthesis) {
      this.loggerInfo('speak|playspeechSynthesis ' + speechSynthesis);
      this.stopTalk();
      this.TTS.text = textIntro;
      if (this.service.getOS() === 'Linux') {
         this.service.audioSerivce(this.TTS.text, this.locale);
      } else {
        speechSynthesis.speak(this.TTS);
      }
    }
  }
  //   this.locale = this.locale.includes('-') ? this.locale : this.locale + '-' + this.locale.toUpperCase();
  //   alert('This feature will be comming soon for Linux Devices!');
  //   // out of scope
  //   this.service.audioSerivce(textIntro, this.locale).subscribe((mp3File) => {
  //     const audio = new Audio(mp3File.toString());
  //     audio.play();
  //   });
  // 'https://translate.google.com/translate_tts?ie=UTF-8&tl='
  // + this.locale + '&client=tw-ob&q=' + textIntro+ '&rd=' + Math.random() * Math.random());
  // }
  setVoice(index: number) {
    this.loggerInfo(index);
    if (speechSynthesis) {
      this.TTS.voice = this.voices[index];
      this.locale = this.voices[index].lang;
      this.voice = this.voices[index];
      this.loggerInfo(this.voice, 'voice activated');
      this.loggerInfo(this.voice.name, 'speaker activated');
      this.setLocale(this.locale);
      this.speak(this.voices[index].name, null);
    }
  }

  setLocale(locale) {
    this.isFriendly = window.top.location.toString().includes('client=true');
    this.locale = locale;
    if (window.top.location.href.includes('&lang=') || window.top.location.pathname.includes('&lang=')) {
      this.locale = window.top.location.href.split('&lang=')[1];
    }
    if (this.locale.includes('-')) {
      this.locale = this.locale.split('-')[0];
    }
    this.wikiLanguage = this.locale;
    this.loggerInfo(this.locale, 'activated', this.wikiLanguage, 'database');
    // this.speak(this.locale + ' : ' + this.wikiLanguage);
    this.setIconFilterNames();
  }

  getListResult(lat, lon) {
    if (window.document.body.getElementsByTagName('input') && window.document.body.getElementsByTagName('input')[0]
      && window.document.body.getElementsByTagName('input')[0].value
      && window.document.body.getElementsByTagName('input')[0].value.trim().length > 3
      && this.textIntro !== this.NO_RESULT) {
      this.isSearch = true;
    } else {
      this.isSearch = false;
    }

    this.loggerInfo('getListResult', lat, lon);

    if ((!lat && !lon) || (lat === 37.788844 && lon === -122.403042 && window.location.hostname.includes('sightguide'))
    ) {
      this.loggerInfo('return|getListResult', lat, lon);
      return;
    }
    // this.isInit = false;
    if (this.resultListSubscription) {
      this.service.getListResult(lat.toString(), lon.toString(), this.wikiLanguage, this.isFeatured);
    }
    this.resultListSubscription = this.service.getListResult(lat.toString(), lon.toString(), this.wikiLanguage, this.isFeatured)
      .subscribe((response: any) => {
        this.POIResults = [];
        this.loggerInfo('getListResult|response = ', response);

        const data = response;
        if (data && data.query && data.query.pages && Object.keys(data.query.pages)) {

          // if (data && data.query && data.query.geosearch && Object.keys(data.query.geosearch)) {
          const pages = data.query.pages;
          this.loggerInfo('pages', pages);

          const pagesList = Object.keys(pages);
          const title = pages[pagesList[0]].title;
          if (title === undefined || title === null) {
            this.loggerInfo('title', title);
            return;
          }
          this.displayedIcon = [];
          for (let i = 0; i < pagesList.length; i++) {
            for (let j = 0; j < 53; j++) {
              if (pages[pagesList[i]].categories) {
                pages[pagesList[i]].categories.forEach((item) => {
                  if (item.title.includes(this.iconTypeString[j])) {
                    this.loggerInfo('its a:', this.iconTypeString[j], ' -> displays as:', this.icons[j]);
                    if (this.hasFilter) {
                      const hasFilterFound = this.filterList.indexOf(this.icons[j].toString());
                      if (hasFilterFound > -1) {
                        this.loggerInfo('setFilter|hasFilter|Match:' + this.icons[j]);
                        const poi = pages[pagesList[i]];
                        poi.distance = this.getAirWayDistance(pages[pagesList[i]].coordinates[0].lat,
                          pages[pagesList[i]].coordinates[0].lon, this.location[0], this.location[1], 'K');
                        poi.icon = this.icons[j];
                        poi.pageimage = pages[pagesList[i]].pageimage;
                        poi.rad = this.setRotation(pages[pagesList[i]].coordinates[0].lat,
                          pages[pagesList[i]].coordinates[0].lon, this.location[0], this.location[1], poi.distance);
                        this.loggerInfo('rad', poi.rad);
                        this.POIResults.push(poi);
                        j = 53;
                      } else if (hasFilterFound === -1) {
                        this.loggerInfo('setFilter|hasFilter: ' + 'no Match');
                      }
                    } else {
                      const poi = pages[pagesList[i]];
                      poi.distance = this.getAirWayDistance(pages[pagesList[i]].coordinates[0].lat,
                        pages[pagesList[i]].coordinates[0].lon, this.location[0], this.location[1], 'K');
                      poi.icon = this.icons[j];
                      poi.pageimage = pages[pagesList[i]].pageimage;
                      poi.rad = this.setRotation(pages[pagesList[i]].coordinates[0].lat,
                        pages[pagesList[i]].coordinates[0].lon, this.location[0], this.location[1], poi.distance);
                      this.POIResults.push(poi);
                      j = 53;
                    }

                  }
                });
              }
            }
            if (!this.hasFilter) {
              this.POIResults[i] = pages[pagesList[i]];
              if (this.POIResults[i] && this.POIResults[i].coordinates) {
                this.POIResults[i].distance = this.getAirWayDistance(this.POIResults[i].coordinates[0].lat,
                  this.POIResults[i].coordinates[0].lon, this.location[0], this.location[1], 'K');
                this.POIResults[i].rad = this.setRotation(this.POIResults[i].coordinates[0].lat,
                  this.POIResults[i].coordinates[0].lon, this.location[0], this.location[1], this.POIResults[i].distance);
              }
            }

          }
          this.POIResults.sort((a, b) => {
            return a.distance - b.distance;
          });
          this.loading = false;
          if (this.isDriveByMode && this.POIResults.length > 0) {
            window.document.getElementsByTagName('button')[0].blur();
            const poi = this.POIResults[0];
            this.getDetails(
              poi.title,
              poi.thumbnail && poi.thumbnail.source
                ? poi.thumbnail.source
                : null, poi.pageimage, poi.rad, poi.distance);
          }

        } else {
          this.loading = false;
          this.loggerInfo('data.query was not found @ location', data.query, +location[0], +location[1]);
        }
      });
  }

  handleOrientationEvent(frontToBack, leftToRight, rotateDegrees) {
    this.loggerInfo('rotateDegrees', rotateDegrees);
    this.rotateDegrees = Math.floor(rotateDegrees);
  }

  getUserAgent() {
    const userAgent = this.windowRef.navigator.userAgent;
    this.loggerInfo('userAgent', userAgent);
    // this.loggerInfo('on mac system', this.isApple || this.getOS() === 'iOS' || this.userAgent === 'safari');
    if (userAgent.match(/(iPad|iPhone|iPod)/i)) {
      return 'ios';
    } else if (userAgent.match(/Firefox/i)) {
      return 'firefox';
    } else if (userAgent.match(/Opera/i)) {
      return 'opera';
    } else if (userAgent.match(/Android/i)) {
      if (this.windowRef.chrome) {
        return 'android_chrome';
      } else {
        return 'android_stock';
      }
    } else if (userAgent.includes('Safari')) {
      return 'safari';
    } else if (userAgent.includes('Opera')) {
      return 'opera';
    } else if (userAgent.includes('Android')) {
      if (this.windowRef.chrome) {
        return 'android_chrome';
      } else {
        return 'android_stock';
      }
    } else if (userAgent.includes('Linux')) {
      return 'linux';
    } else {
      return 'unknown';
    }
  }

  getDeviceOrientation() {
    // this.loggerInfo('Event getDeviceOrientation supported');
    if (!('oncompassneedscalibration' in window)) {
      this.windowRef.addEventListener('compassneedscalibration', (eventgetDeviceOrientation) => {
        this.loggerInfo('oncompassneedscalibration', event);
        // event.preventDefault();
      }, true);
    } else {
      // Event not supported
      alert('getDeviceOrientation NOT supported');
      // window.DeviceMotionEvent.apply('compassneedscalibration');

    }


    if (this.windowRef.DeviceOrientationEvent) { //&& this.windowRef.DeviceOrientationEvent.requestPermission) {
      (DeviceOrientationEvent as any).requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            window.addEventListener('deviceorientation', (event: any) => {
              let alpha = event.alpha;
              this.loggerInfo('deviceorientation event', alpha);
              if (!event.absolute || this.userAgent === 'safari'
                || (!event.absolute || this.isApple || this.service.getOS() === 'iOS' || this.userAgent === 'safari')) {
                alpha = event.webkitCompassHeading * -1;
                this.loggerInfo('deviceorientation webkitCompassHeading', alpha);
              }
              const rotateDegrees = alpha;
              // gamma: left to right
              const leftToRight = event.gamma;
              // beta: front back motion
              const frontToBack = event.beta;

              this.handleOrientationEvent(frontToBack, leftToRight, rotateDegrees);
            }, true);
          }
        })
        .catch((err) => {
          this.loggerInfo(window);
        });
    }
  }

  getCurrentLocation() {
    this.loggerInfo('getCurrentLocation', window.location.hostname);
    if (!window.location.hostname.includes('sightguide')) {
      this.loggerInfo('running local:', window.location.hostname);
      this.getListResult(this.location[0], this.location[1]);
      return;
    }
    // if ('geolocation' in navigator) {
    try {
      navigator.geolocation.getCurrentPosition((location) => {
        this.location[0] = location.coords.latitude;
        this.location[1] = location.coords.longitude;
        this.loggerInfo('this.lastLocation', this.lastLocation, this.location, this.location);
        if (this.lastLocation && this.lastLocation.length > 0) {
          this.heading = this.getbearing(this.lastLocation[0], this.lastLocation[1], this.location[0], this.location[1]);
        }
        this.loggerInfo('this.heading', this.heading);

        this.lastLocation = this.location;

        // this.heading = location.coords.heading;
        // this.loggerInfo('this.heading', this.heading);
        // this.speed = location.coords.speed;
        this.getListResult(this.location[0], this.location[1]);
        this.watchLocation();
        // this.loggerInfo(this.location[0], location.coords.latitude, this.location[1], location.coords.longitude);
      }, (e) => {
        this.handleError(e);
        alert('Please enable device LOCATION');
        navigator.permissions.query({
          name: 'geolocation'
        }).then((result) => {
          if (result.state === 'granted') {
            this.watchLocation();
          }
        });
      });
    } catch (e) {
      alert('Please enable device LOCATION');
      this.loggerInfo('error @ getCurrentLocation', e);
      navigator.permissions.query({
        name: 'geolocation'
      }).then((result) => {
        if (result.state === 'granted') {
          this.watchLocation();
        }
      });
    }
    // }
  }

  watchLocation() {
    this.loggerInfo('watchPosition');
    if (!window.location.hostname.includes('sightguide') || this.hasSearch) {
      // this.loggerInfo('running local:', window.location.hostname);
      this.getListResult(this.location[0], this.location[1]);
      return;
    }
    try {
      navigator.geolocation.watchPosition((location) => {
        try {
          this.location[0] = location.coords.latitude;
        } catch (e) {
          this.handleError(e);
        }
        this.location[1] = location.coords.longitude;
        this.heading = location.coords.heading;
        // this.loggerInfo('this.heading', this.heading);
        // this.speed = location.coords.speed;
        // this.loggerInfo(this.location[0], location.coords.latitude, this.location[1], location.coords.longitude);
        this.getListResult(this.location[0], this.location[1]);
        // this.getListResult(location.coords.latitude, location.coords.longitude);
      }, (e) => {
        this.handleError(e);
      });
    } catch (e) {
      this.handleError(e);
    }
  }

  handleError(e) {
    this.loggerInfo(e);
    this.loggerInfo(e);
    // this.speak(e.toString());
  }

  stopTalk() {
    speechSynthesis.pause();
    speechSynthesis.cancel();
    this.TTS.text = '';
    // this.showDetailedView = false;
  }
  ngOnDestroy() {
    this.speak('', null);
    this.stopTalk();
  }

  download() {
    window.location.href = 'https://play.google.com/store/apps/details?id=io.codespirit.sightguide.app&hl=de';
  }

  setRotation(aimLocationLatitude, aimLocationLongitude, deviceLocationLatitude, deviceLocationLongitude, d) {
    this.loggerInfo('this.speed', this.speed);
    if (this.speed > 0) {
      let bearing = 0;
      bearing = this.getbearing(aimLocationLatitude, aimLocationLongitude, deviceLocationLatitude, deviceLocationLongitude)
      // d, 6371e3);
      // this.loggerInfo('bearing', bearing);
      // let heading = 0;
      // heading = this.getHeading(aimLocationLatitude, aimLocationLongitude, deviceLocationLatitude, deviceLocationLongitude,
      //   d, 6371e3) * Math.PI / 180;
      // this.getDestination(aimLocationLatitude, aimLocationLongitude, deviceLocationLatitude, deviceLocationLongitude,
      // d, 6371e3, bearing );
      // this.loggerInfo('this.heading', this.heading);

      if (this.rotateDegrees) {
        return Math.ceil(bearing - 45); // -45degrees -> for reverting icon base-setting
      } else {
        return NaN;
      }
      // return (Math.ceil((360 + ((bearing + 360) % 360) - heading) % 360)); // rad
    }
    return 0;

  }



  // Converts from degrees to radians.
  toRadians(degrees) {
    return degrees * Math.PI / 180;
  }

  // Converts from radians to degrees.
  toDegrees(radians) {
    return radians * 180 / Math.PI;
  }


  getbearing(destLat, destLng, startLat, startLng) {
    startLat = this.toRadians(startLat);
    startLng = this.toRadians(startLng);
    destLat = this.toRadians(destLat);
    destLng = this.toRadians(destLng);

    const y = Math.sin(destLng - startLng) * Math.cos(destLat);
    const x = Math.cos(startLat) * Math.sin(destLat) -
      Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = this.toDegrees(brng);
    return (brng + 360) % 360;
  }


  getBearing(φ2, λ2, φ1, λ1, d, R): number {
    const y = Math.sin(λ2 - λ1) * Math.cos(φ2);
    const x = Math.cos(φ1) * Math.sin(φ2) -
      Math.sin(φ1) * Math.cos(φ2) * Math.cos(λ2 - λ1);
    const θ = Math.atan2(y, x);
    const brng = (θ * 180 / Math.PI + 360) % 360; // in degrees
    return brng;
  }

  // getDestination(φ2, λ2, φ1, λ1, d, R, brng) {
  //   const dφ2 = Math.asin(Math.sin(φ1) * Math.cos(d / R) +
  //     Math.cos(φ1) * Math.sin(d / R) * Math.cos(brng));
  //   const dλ2 = λ1 + Math.atan2(Math.sin(brng) * Math.sin(d / R) * Math.cos(φ1),
  //     Math.cos(d / R) - Math.sin(φ1) * Math.sin(φ2));
  //   return [dφ2, dλ2];
  // }
  // new //
  // getAirWayDistance(lat1, lon1, lat2, lon2, unit) {
  //   const R = 6371e3; // metres
  //   const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
  //   const φ2 = lat2 * Math.PI / 180;
  //   const Δφ = (lat2 - lat1) * Math.PI / 180;
  //   const Δλ = (lon2 - lon1) * Math.PI / 180;

  //   const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
  //     Math.cos(φ1) * Math.cos(φ2) *
  //     Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  //   let d = R * c; // in metres
  //   if (unit === 'K') { d = d * 1.609344; }
  //   if (unit === 'N') { d = d * 0.8684; }
  //   return Math.ceil(d * 1000);
  // }

  getHeading(lat2, lon2, lat1, lon1, d, R) {
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const aφ2 = lat2 * Math.PI / 180;
    const aΔφ = (lat2 - lat1) * Math.PI / 180;
    const aΔλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(aΔφ / 2) * Math.sin(aΔφ / 2) +
      Math.cos(φ1) * Math.cos(aφ2) *
      Math.sin(aΔλ / 2) * Math.sin(aΔλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const δ = d / R;
    const θ = Math.atan2(aΔλ, 0); // ??
    const Δφ = δ * Math.cos(θ);
    let φ2 = φ1 + Δφ;
    const Δψ = Math.log(Math.tan(φ2 / 2 + Math.PI / 4) / Math.tan(φ1 / 2 + Math.PI / 4));

    const q = Math.abs(Δψ) > 10e-12 ? Δφ / Δψ : Math.cos(φ1); // E-W course becomes ill-conditioned with 0/0

    const Δλ = δ * Math.sin(θ) / q;
    const λ2 = lon1 + Δλ;

    // check for some daft bugger going past the pole, normalise latitude if so
    if (Math.abs(φ2) > Math.PI / 2) {
      φ2 = φ2 > 0 ? Math.PI - φ2 : -Math.PI - φ2;
    }
    return φ2;
  }
  getAirWayDistance(lat1, lon1, lat2, lon2, unit) {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') { dist = dist * 1.609344; }
    if (unit === 'N') { dist = dist * 0.8684; }
    return Math.ceil(dist * 1000);
  }

  switchAllOff() {
    this.useFilter = false;
    this.isInfo = false;
    this.isConfig = false;
    this.isInfo = false;
  }
  onScroll(event) {
    this.getListResult(this.location[0], this.location[1]);
  }

  removeManuelSearchEntry() {
    if (this.isSearch) {
      window.document.body.getElementsByTagName('input')[0].value = '';
    }

    this.isDriveByMode = !this.isDriveByMode;
    // setInterval(() => {
    //   if (this.isDriveByMode && this.debugMode) {
    //     // spendenalert();
    //     // this.speak('Hey? Siri', null);
    //   }}, 30000);
    // if (this.isDriveByMode && this.windowRef.document.body.getElementsByTagName('iframe')[0]) {
    //   this.windowRef.document.body.getElementsByTagName('iframe')[0].click();
    //   this.windowRef.document.body.getElementsByTagName('iframe')[0].setAttribute('style', 'opacity: 1;');
    // } else if (!this.isDriveByMode && this.windowRef.document.body.getElementsByTagName('iframe')[0]) {
    //   this.windowRef.document.body.getElementsByTagName('iframe')[0].setAttribute('style', 'opacity: 0;');
    // }

    // if (this.isDriveByMode && this.windowRef.document.body.getElementsByTagName('video')[0]) {
    //   this.windowRef.document.body.getElementsByTagName('video')[0].setAttribute('muted', 'muted');
    //   this.windowRef.document.body.getElementsByTagName('video')[0].play();
    //   this.windowRef.document.body.getElementsByTagName('video')[0].setAttribute('style', 'opacity: 1;');
    // } else if (!this.isDriveByMode && this.windowRef.document.body.getElementsByTagName('video')[0]) {
    //   this.windowRef.document.body.getElementsByTagName('video')[0].setAttribute('style', 'opacity: 0;');
    // }
  }

  open(url) {
    window.open(url, '_blank');
  }
}
