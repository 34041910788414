import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiRequestsService } from 'src/services/api-requests.service';

@Component({
  selector: 'app-point-of-interest-details',
  templateUrl: './point-of-interest-details.component.html',
  styleUrls: ['./point-of-interest-details.component.scss']
})
export class PointOfInterestDetailsComponent {

  @Input() wikiImage = '';
  @Input() headline = '';
  @Input() infoText = '';
  @Input() coordinates: any = [];
  @Input() extmetadata: any = {};
  @Input() wikiLanguage = '';

  constructor(private service: ApiRequestsService) {}

  getOpenMapsLink() {
    this.loggerInfo('coordinates', this.coordinates);
    if (this.service.getOS() === 'MacOS' || this.service.getOS() === 'OS') {
      window.location.href = 'http://maps.apple.com/?q=' + this.coordinates[0] + ',' + this.coordinates[1];
    } else {
      window.location.href = 'https://maps.google.com/maps?daddr=' + this.coordinates[0] + ',' + this.coordinates[1] + '&amp;ll=';
    }
  }
  loggerInfo(...log) {
    if (window.top.location.href.includes('debugMode=true')){
      console.log('logging: ', ...log);
    }
  }
}
