import { ApiRequestsService } from 'src/services/api-requests.service';
import { Component, OnInit } from '@angular/core';
import { tick } from '@angular/core/testing';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
  TTS: SpeechSynthesisUtterance = new SpeechSynthesisUtterance();
  counter: any;
  run: boolean;

  text = "Wilkommen bei SightGuide app tourist compass";
  textarray: Array<String> = new Array<String>();
  index = 0;
  face;

  intervall;

  startFace(text: string) {
    this.face = '';
    this.getTextSigns(text);
    let i = 0;

    this.intervall = setInterval(() => {
      i++;
      // if (i > this.textarray.length) {
      // this.deleteIntervall();
      // }
      this.face = this.textarray[i];
      // console.log(this.textarray);
      // console.log(this.face);
    }, 40);
  }

  deleteIntervall() {
    this.intervall = null;
  }

  // ngOnInit(): void {
  //     this.start();
  // }
  getTextSigns(text) {
    for (let index = 0; index < text.length; index++) {
      this.textarray[index] = this.text.substring(index, index + 1);
    }
  }

  constructor(private service: ApiRequestsService) {
    this.TTS.text = '';
    this.service.getFanCalls()
      .subscribe((data) => {
        this.counter = data;
      });
  }

  ngOnInit(): void {
    this.TTS.text = 'Willkommen bei SightGuide';
    this.speak(this.TTS.text);
  }

  speak(text) {
    // this.startFace(text);
    if (speechSynthesis) {
      const voices = speechSynthesis.getVoices();
      voices.forEach((value, index) => {
        if (value.toString() === 'en-AU') {
          this.TTS.voice = voices[index];
          return;
        }
      });
      this.TTS.text = text;
      if (this.service.getOS() === 'Linux') {
         this.service.audioSerivce(this.TTS.text, 'de-DE');
      } else {
        speechSynthesis.speak(this.TTS);
      }
    }
  }

}
