import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiAudioSpeechService } from './api.audio.service';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestsService {

  apiKey = '';
  result: any;
  q: any;
  keyword: string;
  dist = 10000;
  imgSize = 300;
  isLinux = false;

  constructor(private https: HttpClient) { }

  getOS() {
    this.isLinux = false;
    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') !== -1) { OSName = 'Windows'; }
    if (navigator.appVersion.indexOf('Mac') !== -1) { OSName = 'MacOS'; }
    if (navigator.appVersion.indexOf('OS') !== -1) { OSName = 'OS'; }
    if (navigator.appVersion.indexOf('X11') !== -1) { OSName = 'UNIX'; }
    if (navigator.appVersion.indexOf('Linux') !== -1) { OSName = 'Linux'; }
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) { OSName = 'iOS'; }
    if (OSName === 'Linux') {
      alert('Linux');
      return 'Linux';
    } else if (OSName !== 'Windows' && OSName !== 'MacOS' && OSName !== 'OS' && OSName !== 'iOS') {
      return 'Linux';
    } else {
      return OSName;
    }
  }

  getCoordinatesResult(keyword, locale, isFeatured = false) {

    const params = '&prop=extracts&exintro=&explaintext=&titles=' + keyword + '&prop=coordinates';
    return this.call(locale, params, isFeatured);
    /*
        return this.https.get('https://'
          + locale +
          '.wikipedia.org/w/api.php?format=json&origin=*&action=query&prop=extracts&exintro=&explaintext=&titles='
          + keyword + '&prop=coordinates');
    */
  }
  getTextIntroResult(keyword, locale, isFeatured = false) {
    const params = '&prop=extracts&exintro=&explaintext=&titles=' + keyword;

    return this.call(locale, params, isFeatured);

    /* return this.https.get('https://'
       + locale +
       '.wikipedia.org/w/api.php?formatf=json&origin=*&action=query&prop=extracts&exintro=&explaintext=&titles='
       + keyword);
       */
  }

  getListResult(lat, lng, locale, isFeatured = false) {
    // https://de.wikipedia.org/w/api.php?format=json&origin=*&action=query&generator=geosearch&prop=coordinates%7Ccategories%7Cpageimages&piprop=thumbnail%7Cname&pithumbsize=300&ggsradius=10000&ggscoord=48.7035319%7C9.144533&cllimit=500&colimit=500&ggsprop=dim&ggslimit=100
    const geoLocationRequest =
      '&generator=geosearch&prop=coordinates%7Ccategories%7Cpageimages&piprop=thumbnail%7Cname&pithumbsize=' +
      this.imgSize + '&ggsradius=' + this.dist + '&ggscoord=' +
      lat + '%7C'
      + lng
      + '&cllimit=500&colimit=500&ggsprop=dim&ggslimit=100';

    const imagerequest =
      '&generator=geosearch&prop=coordinates%7Cpageimages&piprop=thumbnail%7Cname&pithumbsize=' +
      this.imgSize + '&ggsradius=' + this.dist + '&ggscoord=' +
      lat + '%7C'
      + lng +
      '&ggsprop=dim';
    return this.call(locale, geoLocationRequest, isFeatured);
    /*    return this.https.get('https://'
          + locale +
          '.wikipedia.org/w/api.php?format=json&origin=*&action=query' + geoLocationRequest);
    */
  }

  getMetadataByImageNameUrl(pageimage, locale, isFeatured = false) {
    const params = '&titles=Image:' + pageimage + '&prop=imageinfo&iiprop=extmetadata';
    return this.call(locale, params, isFeatured);
    /*return this.https.get('https://'
      + locale +
      '.wikipedia.org/w/api.php?action=query&origin=*&format=json&titles=Image:' + pageimage + '&prop=imageinfo&iiprop=extmetadata');
      */
  }

  translate(locale, textIntro, isFeatured = false) {
    locale = locale.split('-')[0];
    // let params = new HttpParams();
    // params = params.append('q', textIntro);
    // params = params.append('target', locale);
    // params = params.append('key ', this.apiKey);
    // out of scope
    // return this.https.get('https://translate.google.com/translate_tts?ie=UTF-8&tl='
    //   + locale + '&tk=52269.458629&q='
    //   + textIntro + '&client=tw-ob');

    // return this.https.get('https://translate.google.com/translate_tts?ie=UTF-8&tl='
    // + locale + '&client=tw-ob&q=' + textIntro + '&rd=' + Math.random() * Math.random());
    //   // 'https://translation.googleapis.com/language/translate';
  }

  call(locale, params, isFeatured) {
    const wikiApiUrl = 'https://' + locale + '.wikipedia.org/w/api.php?format=json&origin=*&action=query' + params;
    const localApi = 'https://SightGuide.app/api/index.php?params=true' + params + '&wikilocale=' + locale;
    const relativeLocalApi = '../../../api/index.php?params=true' + params + '&wikilocale=' + locale;
    // const url = isFeatured === true ? localApi : wikiApiUrl;
    // const url = localApi; // dev
    const url = localApi; // dev

    return this.https.get(url);
  }

  getFanCalls() {
    const apiUrl = 'https://SightGuide.app/api/app-calls.php';
    return this.https.get(apiUrl);
  }

  audioSerivce(text, locale) {
    const ttsRecorder = new ApiAudioSpeechService(
      text, {
      voice: 'english-us espeak',
      lang: locale,
      pitch: 0.75,
      rate: 1,
    },
      {},
      {}
    )
      // .start()
      // .then((tts: any) => tts.mediaSource())
      // .then(({tts, data}) => {
      //   console.log(tts, data);
      //   `data` : `MediaSource`
      //   tts.audioNode.srcObj = data;
      //   tts.audioNode.title = tts.utterance.text;
      //   tts.audioNode.onloadedmetadata = () => {
      //     console.log(tts.audioNode.duration);
      //     tts.audioNode.play();
      //   }});

      // .start()
      // // `tts` : `ApiAudioSpeechService` instance, `data` : audio as `dataType` or method call result
      // .then(tts => tts.arrayBuffer())
      // .then(({tts, data}) => {
      //   // do stuff with `ArrayBuffer`, `AudioBuffer`, `Blob`,
      //   // `MediaSource`, `MediaStream`, `ReadableStream`
      //   // `data` : `ArrayBuffer`
      //   tts.audioNode.src = URL.createObjectURL(new Blob([data], {type:tts.mimeType}));
      //   tts.audioNode.title = tts.utterance.text;
      //   tts.audioNode.onloadedmetadata = () => {
      //     console.log(tts.audioNode.duration);
      //     tts.audioNode.play();
      //   };
      // });
      // // AudioBuffer
      // ttsRecorder
      // .start()
      // .then((tts => tts.audioBuffer()))
      // .then(({tts, data}) => {
      //   `data` : `AudioBuffer`
      //   let source = tts.audioContext.createBufferSource();
      //   source.buffer = data;
      //   source.connect(tts.audioContext.destination);
      //   source.start();
      // });
      // // Blob
      // ttsRecorder

      // .start()
      // .then((tts: any) => tts.blob())
      // .then(({tts, data}) => {
      //   // `data` : `Blob`
      //   tts.audioNode.src = URL.createObjectURL(tts.blob);
      //   tts.audioNode.title = tts.utterance.text;
      //   tts.audioNode.onloadedmetadata = () => {
      //     console.log(tts.audioNode.duration);
      //     tts.audioNode.play();
      //   };
      // });

      // // ReadableStream
      // ttsRecorder

      .start()
      .then((tts: any) => tts.readableStream())
      .then(({ tts, data }) => {
        // `data` : `ReadableStream`
        console.log(tts, data);
        data.getReader().read().then(({ value, done }) => {
          tts.audioNode.src = URL.createObjectURL(value[0]);
          tts.audioNode.title = tts.utterance.text;
          tts.audioNode.onloadedmetadata = () => {
            console.log(tts.audioNode.duration);
            tts.audioNode.play();
          };
        });
      });
    // MediaSource
    // ttsRecorder.start()
    // .then(tts => tts.mediaSource())
    // .then(({tts, data}) => {
    //   console.log(tts, data);
    //   // `data` : `MediaSource`
    //   tts.audioNode.srcObj = data;
    //   tts.audioNode.title = tts.utterance.text;
    //   tts.audioNode.onloadedmetadata = () => {
    //     console.log(tts.audioNode.duration);
    //     tts.audioNode.play();
    //   });
    // })
    // // MediaStream
    // let ttsRecorder = new ApiAudioSpeechService({
    //   text: "The revolution will not be televised",
    //   utternanceOptions: {
    //     voice: "english-us espeak",
    //     lang: "en-US",
    //     pitch: .75,
    //     rate: 1
    //   },
    //   dataType:"mediaStream"
    // });
    // ttsRecorder.start()
    // .then(({tts, data}) => {
    //   // `data` : `MediaStream`
    //   // do stuff with active `MediaStream`
    // })
    // .catch(err => console.log(err))

  }

}
