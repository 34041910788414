
<section id="loading" *ngIf="loading">

  <video
    id="video"
    *ngIf="!isFriendly"
    id="video"
    autoplay="autoplay"
    loop="loop"
    muted="muted"
    playsinline="true"
    preload="auto"
    src="../../assets/water.mp4"
  ></video>
  <a
    href="https://www.booking.com/index.de.html?aid=7961512&sb_price_type=total&lang_changed=1&lang=de-de"
    *ngIf="wikiLanguage === 'de'"
  >
    <img src="../../assets/DE.png" id="img"
  /></a>
  <a
    href="https://www.booking.com/index.de.html?aid=7961512&sb_price_type=total&lang_changed=1&lang=en-gb"
    *ngIf="wikiLanguage === 'en'"
  >
    <img src="../../assets/EN.png" id="img"
  /></a>
  <a
    href="https://www.booking.com/index.de.html?aid=7961512&sb_price_type=total&lang_changed=1&lang=it"
    *ngIf="wikiLanguage === 'it'"
  >
    <img src="../../assets/IT.png" id="img"
  /></a>
  <a
    href="https://www.booking.com/index.de.html?aid=7961512&sb_price_type=total&lang_changed=1&lang=fr"
    *ngIf="wikiLanguage === 'fr'"
  >
    <img src="../../assets/FR.png" id="img"
  /></a>
  <a
    href="https://www.booking.com/index.de.html?aid=7961512&sb_price_type=total&lang_changed=1&lang=es"
    *ngIf="wikiLanguage === 'es'"
  >
    <img src="../../assets/ES.png" id="img"
  /></a>
  <a
    href="https://www.booking.com/index.de.html?aid=7961512&sb_price_type=total&lang_changed=1&lang=zh-cn"
    *ngIf="wikiLanguage === 'zh'"
  >
    <img src="../../assets/CN.png" id="img" />
  </a>

  <a
    href="https://www.booking.com/index.de.html?aid=7961512"
    *ngIf="
      wikiLanguage !== 'zh' &&
      wikiLanguage !== 'de' &&
      wikiLanguage !== 'en' &&
      wikiLanguage !== 'es' &&
      wikiLanguage !== 'fr' &&
      wikiLanguage !== 'it'
    "
  >

    <img src="../../assets/EN.png" id="img" />
  </a>

</section>
<div
  class="container"
  (mousemove)="loggerInfo('initiated start'); getDeviceOrientation()"
  (click)="loggerInfo('initiated start'); getDeviceOrientation()"
>
  <!-- <script ngIf="!isMobileApp">
    (adsbygoogle = window.adsbygoogle || []).push({});
  </script> -->

  <div>
    <section class="absolute" id="configurations" *ngIf="isConfig">
      <i class="fas fa-times-circle fa-2x" (click)="switchAllOff()"></i>
      <div *ngIf="voices && voices[0] && voices[0]">
        <label>select Speaker:&nbsp;</label>
        <select name="voices" #voiceRef (change)="setVoice(voiceRef.value)">
          <option
            value="{{ i }}"
            *ngFor="let voice of voices; let i = index"
            [selected]="voice.lang.includes(locale)"
          >
            {{ voice.name }}&nbsp;{{ voice.lang }}
          </option>
        </select>
        <br />
        <label>select Database:&nbsp;</label>
        <select
          name="lang"
          (change)="setLocale(languageRef.value)"
          #languageRef
        >
          <option
            value="{{ voice.lang }}"
            *ngFor="let voice of voices"
            [selected]="voice.lang.includes(locale)"
          >
            {{ voice.lang }} &nbsp;{{ voice.lang.split("-")[0] }}
          </option>
        </select>
      </div>

      <div *ngIf="voice">Speaker: {{ voice.name }} {{ voice.lang }}</div>
      <br />
      <div *ngIf="locale || (locale && wikiLanguage)">
        Language: {{ locale }} &nbsp; Database:{{ wikiLanguage }}
      </div>
      Update Location:
      <button
        id="geoBtn"
        #geoBtn
        (click)="isManualClicked = true; getCurrentLocation()"
      >
        {{ location[0] }} {{ location[1] }}
      </button>
    </section>
    <section id="impressum" class="absolute" *ngIf="isInfo">
      <div>
        <i class="fas fa-times-circle fa-2x" (click)="switchAllOff()"></i>
        <ul>
          <li>
            <a
              class="button"
              target="_blank"
              href="https://www.sightguide.app/Impressum-SightGuide.pdf"
              >Impressum</a
            >
            <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#ImpressumModal">
              Impressum
            </button> -->
          </li>
          <li>
            <a
              class="button"
              target="_blank"
              href="https://www.sightguide.app/Nutzungsbedingungen-SightGuide.pdf"
              >Nutzungsbedingungen</a
            >
            <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#NutzungsbedingungenModal">
              Nutzungsbedingungen
            </button> -->
          </li>
          <li>
            <a
              class="button"
              target="_blank"
              href="https://www.sightguide.app/Datenschutz-SightGuide.pdf"
              >Datenschutz</a
            >
            <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#DatenschutzModal">
              Datenschutz
            </button> -->
          </li>
        </ul>
      </div>
    </section>
    <section *ngIf="useFilter" class="absolute mt-3">
      <i class="fas fa-times-circle fa-2x" (click)="switchAllOff()"></i>
      <div class="scroll-content height-75">
        <div class="inline">
          <i class="fas fa-2x fa-filter filter-name">&nbsp; Filter &nbsp;</i>
          <label class="switch">
            <input
              type="checkbox"
              [checked]="filterAll === true ? true : false"
              (change)="setFilterAll()"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <span *ngFor="let icon of icons; let i = index">
          <div
            class="inline"
            *ngIf="i < icons.length && icons[i] !== icons[i + 1]"
          >
            <i class="fas fa-2x fa-{{ icon }} filter-name"
              >&nbsp;{{ iconTypeString[i] }}&nbsp;</i
            >
            <label class="switch">
              <input
                type="checkbox"
                [checked]="
                  (filterList.indexOf(icon.toString()) !== -1 &&
                  icon === filterList[filterList.indexOf(icon.toString())]) ? true : false
                "
                (change)="setFilter(icon)"
              />
              <span class="slider round"></span>
            </label>
          </div>
          <br />
        </span>
      </div>
    </section>
    <nav class="fixed" [class.withOutSearch]="!hasSearch">
      <div id="features" [class.withOutSearch]="!hasSearch">
        <span class="navitems">
          <!-- <i class="fas fa-car"></i> -->
          <button
            id="auto"
            (click)="speak('', null); removeManuelSearchEntry()"
            [class.running]="isDriveByMode"
            *ngIf="isDriveByMode"
            class="CODESPIRIT-ICONS-BUTTON codespirit-car"
          ></button>
          <button
            (click)="speak('', null); removeManuelSearchEntry()"
            *ngIf="!isDriveByMode"
            class="CODESPIRIT-ICONS-BUTTON codespirit-car-fix"
          ></button>
          <!-- <button
            (click)="this.getListResult(this.location[0], this.location[1])"
          >
            <i class="fas fa-sync-alt"></i>
          </button> -->

          <!-- <button (click)="setFilterAll()" [class.running]="filterAll">
            <i class="fas fa-camera-retro"></i>
          </button> -->
          <button
            *ngIf="useFilter"
            class="CODESPIRIT-ICONS-BUTTON codespirit-filter"
            (click)="useFilter = !useFilter"
            [class.running]="hasFilter"
          >
            <!-- <i class="fas fa-filter"></i> -->
          </button>
          <button
            *ngIf="!useFilter"
            class="CODESPIRIT-ICONS-BUTTON codespirit-filter-fix"
            (click)="useFilter = !useFilter"
          ></button>

          <!-- <i class="fas fa-search"></i> -->
          <button
            *ngIf="hasSearch"
            (click)="hasSearch = !hasSearch; isDriveByMode = false"
            class="CODESPIRIT-ICONS-BUTTON codespirit-search"
            [class.running]="hasSearch"
          ></button>
          <button
            *ngIf="!hasSearch"
            (click)="hasSearch = !hasSearch; isDriveByMode = false"
            class="CODESPIRIT-ICONS-BUTTON codespirit-search-fix"
          ></button>
          <!-- <i class="fas fa-cogs"></i> -->
          <button
            *ngIf="isConfig"
            (click)="isConfig = !isConfig; isInfo = false"
            class="CODESPIRIT-ICONS-BUTTON codespirit-cogs-"
            [class.running]="isConfig"
          ></button>
          <button
            *ngIf="!isConfig"
            (click)="isConfig = !isConfig; isInfo = false"
            class="CODESPIRIT-ICONS-BUTTON codespirit-cogs-fix"
          ></button>

          <!-- <i class="fas fa-info"></i> -->
          <button
            *ngIf="isInfo"
            (click)="isInfo = !isInfo"
            class="CODESPIRIT-ICONS-BUTTON codespirit-info"
            [class.running]="isInfo"
          ></button>
          <button
            *ngIf="!isInfo"
            (click)="isInfo = !isInfo"
            class="CODESPIRIT-ICONS-BUTTON codespirit-info-fix"
          ></button>
        </span>
        <div *ngIf="debugMode">rotateDegrees: {{ rotateDegrees }}</div>
        <input
          *ngIf="hasSearch"
          id="search"
          type="text"
          name="search"
          #input
          (keyup)="search(input.value)"
          (keydown)="stopTalk()"
          placeholder="Search.."
          (submit)="search(input.value)"
          (change)="search(input.value)"
        />

        <!-- <section>
          <h1 class="mt-2">@: {{ location[0] }} | {{ location[1] }}</h1>
        </section> -->
      </div>

      <!-- <span>this.heading: {{heading}}</span>
      <br />
      <span>heading: {{calcHeading}}</span> -->
      <!-- <div class="row">
        <div class="col-12">rotateDegrees: {{rotateDegrees}}</div>
      </div> -->
    </nav>

    <div *ngIf="!isMobileApp">
      <!-- Reisen & Lokale Infos -->
      <!-- <ins
        class="adsbygoogle"
        style="display: block;"
        data-ad-client="ca-pub-5796806471143246"
        data-ad-slot="5592781146"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins> -->
    </div>

    <section
      class="row scroll-content overflow-hidden"
      id="content"
      [class.withOutSearch]="!hasSearch"
      (scroll)="onScroll($event)"
    >
      <div
        class="col-md-4 col-xl-4 col-sm-12 p-0"
        id="app-point-of-interest-details"
        *ngIf="showDetailedView"
      >
        <br />

        <br />
        <br />
        <div class="row">
          <div class="col-12">
            <i
              class="fas fa-times-circle fa-2x"
              (click)="showDetailedView = false"
            ></i>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-12" *ngIf="poi && poi.distance">
            {{ poi.distance }} meter
          </div>

          <div class="col-12">
            <a
              class="center-2x-icon"
              target="_blank"
              (click)="mapsWasOpened = true"
              href="{{ getOpenMapsLink(poi) }}"
              ><span class="inline">
                <span>
                  &nbsp;
                  <i
                    class="fas fa-1x fa-location-arrow compass-rotation-use-{{
                      rad + rotateDegrees
                    }}"
                  ></i
                ></span>
              </span>
            </a>
            <span *ngIf="debugMode === true">rad: {{ rad }}</span>
            <span *ngIf="debugMode === true">heading: {{ heading }}</span>
            <span *ngIf="debugMode === true">language: {{ locale }}</span>
            <span *ngIf="debugMode === true"
              >rotateDegrees: {{ rotateDegrees }}</span
            >
          </div>
        </div>

        <app-point-of-interest-details
          *ngIf="showDetailedView"
          [wikiImage]="wikiImage"
          [headline]="headline"
          [infoText]="infoText"
          [coordinates]="artikleCoordinates"
          [wikiLanguage]="wikiLanguage"
          [extmetadata]="extmetadata"
          class="p-0 pt-5"
        >
        </app-point-of-interest-details>
      </div>
      <div
        class="overflow-hidden"
        [class.col-12]="!showDetailedView"
        [class.col-md-8]="showDetailedView"
        [class.col-xl-8]="showDetailedView"
        [class.col-sm-12]="showDetailedView"
        *ngIf="POIResults && POIResults[0] && POIResults[0].title"
      >
        <ul>
          <li
            class="poi"
            *ngFor="let poi of POIResults; let i = index"
            (click)="
              speak('', null);
              isManualClicked = true;
              isInit = true;
              getDetails(
                poi.title,
                poi.thumbnail && poi.thumbnail.source
                  ? poi.thumbnail.source
                  : null,
                poi.pageimage,
                poi.rad,
                poi.distance,
                poi
              )
            "
          >
            <!-- <button> -->
            <div
              *ngIf="poi && poi.thumbnail && poi.thumbnail.source"
              class="thumbimage"
            >
              <img
                src="{{ poi.thumbnail.source }}"
                width="auto"
                height="100px"
              />
            </div>
            <div class="textcontent" *ngIf="poi && poi.title">
              <div class="row-1">
                <span class="inline"
                  ><h4>&nbsp; {{ poi.title }}</h4></span
                >
              </div>
              <div class="inline-row-1">
                <span class="inline categoryIcon">
                  <!-- <i class="fas fa-{{ poi.icon }}"></i> -->
                  <div class="CODESPIRIT-ICONS codespirit-{{ poi.icon }}"></div>
                </span>
              </div>
              <div class="row-2">
                <a
                  target="_blank"
                  (click)="mapsWasOpened = true"
                  href="{{ getOpenMapsLink(poi) }}"
                  ><span class="inline">
                    <span>
                      &nbsp;
                      <i
                        class="fas fa-1x fa-location-arrow compass-rotation-use-{{
                          poi.rad + rotateDegrees
                        }}"
                      ></i
                    ></span>
                  </span>
                </a>
                <span class="inline" *ngIf="poi && poi.distance"
                  ><sup>
                    &nbsp;
                    {{ poi.distance }}
                    meter
                  </sup></span
                >
                <span class="inline"
                  ><sup>
                    &nbsp;
                    <i class="fa fa-location-route"></i></sup
                ></span>
                <!-- <span class="inline"
                  ><sup>&nbsp; {{ poi.coordinates[0].globe }}</sup>
                </span> -->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
  <!-- {{ wikiLanguage }} {{locale}} -->
</div>
<div id="imprint bottom">
  <!-- <button class="default" (click)="open('https://www.sightguide.app/Impressum-SightGuide.pdf')">Impressum</button> -->
  <!-- <section
    [class.interaction]="!speechSynthesis.speaking"
    [class.speaking]="speechSynthesis.speaking"
    *ngIf="!loading && isInit"
  ></section> -->
</div>

<!--
<div class="modal fade" id="ImpressumModal" tabindex="-1" role="dialog" aria-labelledby="ImpressumModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ImpressumModalLabel">Impressum</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ImpressumModal
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="NutzungsbedingungenModal" tabindex="-1" role="dialog" aria-labelledby="NutzungsbedingungenModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="NutzungsbedingungenModalLabel">Nutzungsbedingungen</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Nutzungsbedingungen
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="DatenschutzModal" tabindex="-1" role="dialog" aria-labelledby="DatenschutzModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="DatenschutzModalLabel">Datenschutz</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Datenschutz
            </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div> -->
